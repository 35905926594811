<template>
  <div class="container">
    <div class="backimg">
      <img class="img" src="../../assets/background2.png" alt="" />
    </div>
    <div class="login_logo">
      <el-form class="user" :model="user" :rules="rules" ref="user">
        <div class="picture">
          <img src="@/assets/picture.png" alt="" />
        </div>
        <el-form-item prop="username" class="inp">
          <el-input
            type="text"
            v-model="user.username"
            class="input"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="password" class="inp">
          <el-input
            type="password"
            v-model="user.password"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item> -->
        <el-form-item class="btn">
          <el-button type="primary" @click="login('user')" class="login"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div v-loading.fullscreen.lock="loading"></div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
export default {
  data() {
    return {
      loading: false,
      user: {
        username: "",
        // password: "",
      },
      rules: {
        username: [
          { required: true, message: "未输入用户名", trigger: "blur" },
          { min: 3, max: 10, message: "请输入3至10位字符" },
        ],
      },
    };
  },
  methods: {
    login() {
      console.log(this.user);
      content: this.user;
      this.loading = true;
      this.$axios
        .post("/admin/login", {
          content: this.user,
        })
        .then((res) => {
          if (res.data.code == -1) {
            this.loading = false;
            this.$message.error("好好检查用户名和密码哦");
          } else if (res.data.code == 0) {
            Cookie.set("token", res.data.token);
            this.$store.commit("setToken", res.data.token);
            this.$store.commit("changIsSignIn", 1);
            this.$message({
              message: "登陆成功,去做一些想做的事情吧",
              type: "success",
            });
            setTimeout(() => {
              this.loading = false;
              this.$router.push({ name: "Home" });
            }, 1000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    submit(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="scss">
* {
  padding: 0;
  margin: 0;
}
/*搜索组件最外层div */

/*搜索input框 */

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .backimg img {
    width: 100vw;
    height: 100vh;
  }
  .login_logo {
    // width: 480px;
    // height: 420px;
    // background-color: #f8fafc;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;

    .user {
      .picture {
        width: 90px;
        height: 90px;
        transform: translate(90%, -23%);
        --tw-bg-opacity: 1;
        background-color: rgba(221, 221, 221, var(--tw-bg-opacity));
        border-radius: 9999px;
        display: inline-block;
        overflow: hidden;
        position: relative;

        img {
          // width: 90px;
          // height: 90px;
          // border-radius: 150px;
          border-radius: 9999px;
          height: 100%;
          max-width: 100%;
          animation: scale 0.5s ease-out;
          animation-duration: 0.5s;
          animation-timing-function: ease-out;
          animation-delay: 0s;
          animation-iteration-count: 1;
          animation-direction: normal;
          animation-fill-mode: none;
          animation-play-state: running;
          animation-name: scale;
          @keyframes scale {
            0% {
              transform: scale(0);
            }
            100% {
              transform: scale(1);
            }
          }
        }
      }

      margin-top: 150px;
      .inp {
        width: 270px;
        margin: 26px auto;
        .input {
          ::v-deep .el-input__inner {
            border-radius: 23px;
            height: 35px;
            width: 230px;
            margin-left: 17px;
            margin-bottom: 10px;
          }
        }
        ::v-deep .el-input__inner:focus {
          border: 1px solid #5eaa91;
        }
        ::v-deep .el-form-item__error {
          color: #5eaa91;
          border: #5eaa91;
        }
      }
      .btn {
        position: relative;
        left: 56%;
        bottom: 10px;
        transform: translate(-18%);
      }
      .login {
        width: 55px;
        height: 35px;
        // background-color: #5e72e4;
        background-color: #5eaa91;
        border: #5eaa91;
      }
    }
  }
}
.el-input {
  box-shadow: 0 0 0 8;
}

@media (max-width: 800px) {
  container {
    .login_logo {
      opacity: 0.88;
      width: 340px;
      height: 250px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      .login_img {
        width: 110px;
        height: 110px;
        border: 50%;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 10px rgb(83, 82, 82);
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user {
        position: relative;
        top: -24%;
        .inp {
          width: 300px;
          margin: 26px auto;
        }
        .btn {
          position: relative;
          left: 50%;
          bottom: 10px;
          transform: translate(-18%);
        }
        .login {
          width: 50px;
          height: 30px;
        }
        .update {
          width: 50px;
          height: 30px;
        }
      }
    }
  }
}
</style>
